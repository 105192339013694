@import "@fontsource/roboto";

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
body {
    position: relative;
    box-sizing: border-box;
    margin: 0;
    font-size: 20px;
    font-family: "Roboto";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
@media (max-width: 768px) {
    #root {
        margin-top: 0px;
        height: 86.5vh;
    }
}
#root {
    height: 100vh;
}
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}
input[type="number"] {
    -moz-appearance: textfield;
}
textarea::placeholder {
    font-size: 16px !important;
}
input:focus {
    border-color: #33ca75 !important;
}
