$gray-background: #f5f5f5;
$light-gray-background: rgb(250, 250, 250);
$white-background: #ffffff;
$red-accent: #ca333d;
$light-red: rgba(202, 51, 61, 0.15);
$red-accent-with-opacity: #faebec; //#F9E5E7;
$gray-text: #9f9f9f;
$light-gray-text: rgba(159, 159, 159, 0.5);
$secondary-gray-text: #828282;
$gray-label: rgba(38, 50, 56, 0.5);
$gray-dot: rgba(159, 159, 159, 0.35);
$black-text: #263238;
$light-green: #eaf9f1;
$green-text: #33ca75;
$badge-grey: rgba(0, 0, 0, 0.4);
$border-white: #c4c4c4;
$modal-overlay-background: rgba(190, 190, 190, 0.3);
$black-background: rgba(38, 50, 56, 0.5);
$pure-black: black;
$grey-box-shadow: rgba(229, 229, 229, 0.6);
$popover-background: #898989;
$yellow-background: #f6f5cf;
$yellow-text: #f5a62f;
$transparent-color: rgba(0, 0, 0, 0);
$dark-blue: #3388ca;
$border-light-gray: rgba(229, 229, 229, 0.5);
$dark-red: #b12d35;
$dark-green: #2db166;
$dark-gray-color: #dadada;
$blue-text: rgba(44, 163, 255, 1);
$blue-background: rgba(44, 163, 255, 0.15);
$gantt-light-blue: #97d1ff;
$gantt-light-orange: #fad59c;
$gantt-light-green: #a5e9c2;
