@import "src/Styles/Common.scss";
.successful-verification-page {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    .wrapper {
        display: flex;
        align-items: center;
        justify-content: space-around;
        height: 100vh;
        background: $gray-background;
        .content {
            background: $white-background;
            box-shadow: 0px 4px 24px $grey-box-shadow;
            border-radius: 24px;
            width: 770px;
            height: 459px;
            display: flex;
            flex-direction: column;
            align-items: center;
            .one-icon {
                width: 30%;
                margin-top: 60px;
            }
            .title-text {
                @include font-roboto;
                font-weight: bold;
                font-size: 24px;
                margin-top: 45px;
            }
            .subtitle-text {
                @include font-roboto-small;
                font-size: 18px;
                margin-top: 16px;
            }
            .log-in-button {
                background: $light-green;
                border-radius: 6px;
                width: 610px;
                height: 56px;
                display: flex;
                justify-content: center;
                align-items: center;
                text-decoration: none;
                border: none;
                margin-top: 32px;
            }
            .log-in-button-text {
                @include font-roboto-small;
                color: $green-text;
                font-size: 16px;
                font-weight: 700;
            }
            .help-link {
                color: $gray-text;
                margin-top: 32px;
                font-size: 16px;
            }
            a {
                color: inherit;
            }
        }
    }
}
