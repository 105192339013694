@import "src/Styles/CommonColors.scss";
@import "src/Styles/TitleHeaders.scss";
@import "src/Styles/ClickableItems.scss";
@import "src/Styles/SideBar.scss";
@import "src/Styles/Filters.scss";
@import "react-datepicker/dist/react-datepicker.css";
$laptop-width: 1600px;
$small-laptop-width: 1024px;
$small-laptop-max-width: 1279px;
$tablet-width: 768px;
$tablet-max-width: 1023px;
$laptop-height: 900px;
$hd-resolution-width: 1280px;
$medium-laptop-width: 1280px;
$semi-large-laptop-max: 1439px;
$medium-laptop-max-width: 1599px;
@mixin column-height {
    @media (max-width: $laptop-width) {
        height: 48px;
    }
    @media (min-width: $laptop-width) {
        height: 60px;
    }
    display: flex;
    align-items: center;
}

@mixin subNavigationTitleStyles {
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    display: flex;
    justify-content: center;
}

@mixin wrapLongText {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

@mixin column-box-shadow-center {
    width: calc(100% + 8px);
    border-bottom: 2px solid $grey-box-shadow;
}

@mixin date-label-style {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.1px;
    @include wrapLongText;
}

@mixin label-style {
    @include date-label-style;
    color: $gray-label;
    margin-bottom: 5px;
}

@mixin bigger-label-style {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 0.1px;
    color: $gray-label;
    @include wrapLongText;
}

@mixin smaller-label-style {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.1px;
    color: $gray-label;
    @include wrapLongText;
}

@mixin input-and-label-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
}

@mixin inactive-nav-bar {
    height: 4px;
    background-color: $gray-background;
    width: 100%;
    border-radius: 8px;
}

@mixin font-family-roboto {
    font-family: Roboto;
    font-style: normal;
}

@mixin text-sans-decorations {
    @media (min-width: $laptop-width) {
        font-weight: bold;
        font-size: 14px;
        letter-spacing: 0.1px;
        @include wrapLongText;
    }
    @media (max-width: $laptop-width) {
        font-weight: bold;
        font-size: 12px;
        letter-spacing: 0.1px;
        @include wrapLongText;
    }
}

@mixin text-decorations {
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    @include wrapLongText;
}

@mixin font-roboto {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.1px;
    @include wrapLongText;
}

@mixin modal-black-label {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: $black-text;
    @include wrapLongText;
}

@mixin gray-date-text {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.1px;
    color: $gray-text;
    @include wrapLongText;
}
@mixin font-roboto-small {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: $secondary-gray-text;
    @include wrapLongText;
}

@mixin font-roboto-big {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    color: $black-text;
    @include wrapLongText;
}

@mixin log-out-text {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    @include wrapLongText;
}

@mixin form-title--label {
    @media (min-width: $laptop-width) {
        font-weight: bold;
        font-family: Roboto;
        font-style: normal;
        font-size: 34px;
        line-height: 44px;
        letter-spacing: 0.25px;
        @include wrapLongText;
    }
    @media (max-width: $laptop-width) {
        font-weight: bold;
        font-family: Roboto;
        font-style: normal;
        font-size: 24px;
        line-height: 31px;
        letter-spacing: 0.25px;
        @include wrapLongText;
    }
}

@mixin form-small-title--label {
    font-weight: bold;
    font-size: 24px;
    line-height: 31px;
    letter-spacing: 0.25px;
}

@mixin highlighted-search-labels-font {
    @include font-family-roboto;
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 0.01em;
}

@mixin widget-font {
    @include font-family-roboto;
    font-weight: bold;
    font-size: 22px;
    letter-spacing: 0.01em;
}

@mixin search-labels-font {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
    @include wrapLongText;
}

@mixin search-labels-font-bolded {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
    @include wrapLongText;
}

@mixin input {
    border: 1px solid $border-white;
    box-sizing: border-box;
    border-radius: 6px;
    height: 48px;
    @include font-family-roboto;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: $black-text;
    padding: 10px;
    width: 100%;
}

@mixin save-and-cancel-buttons-wrapper {
    width: 185px;
    margin-top: 25px;
    display: flex;
    justify-content: space-between;
}

@mixin placeholder-style {
    @include font-family-roboto;
    @include search-labels-font;
    font-weight: bold;
    color: $gray-label;
}

@mixin login-page-text {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    color: $black-text;
}

@mixin small-label-text {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    @include wrapLongText;
}

@mixin task-labels {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.1px;
}

@mixin green-button-text {
    @include font-family-roboto;
    font-weight: bold;
    font-size: 12px;
    letter-spacing: 0.75px;
    text-transform: uppercase;
}

@mixin no-access-title {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 31px;
    text-align: center;
    color: $red-accent;
}

@mixin forms-answer-label {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 0.1px;
}

@mixin textarea-style {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: $black-text;
    @include wrapLongText;
}
@mixin very-small-gray-label {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 13px;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    color: #9f9f9f;
    @include wrapLongText;
}
